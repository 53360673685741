<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Chi tiết đăng ký thanh toán tự động</h6>
              </template>
              <b-row>
                <b-col><b>Số điện thoại: </b> {{ formInfo.user_phone }}</b-col>
                <b-col
                  ><b>Loại dịch vụ: </b>
                  <span v-if="formInfo.action === 'TOPUP_MOBILE'"
                    >Nạp tiền điện thoại</span
                  >
                  <span v-else-if="formInfo.action === 'TOPUP_DATA'"
                    >Nạp data 3G/4G</span
                  >
                  <span v-else>{{ formInfo.action }}</span></b-col
                >
                <b-col><b>Nhà mạng: </b> {{ formInfo.service }}</b-col>
                <b-col><b>Mệnh giá: </b> {{ formInfo.price }}</b-col> </b-row
              ><br />
              <b-row>
                <b-col
                  ><b>Tần suất nạp: </b> {{ formInfo.frequent_load }}</b-col
                >
                <b-col
                  ><b>Trạng thái: </b>
                  <b-badge v-if="formInfo.is_active === 1" variant="success"
                    >Hoạt động</b-badge
                  >
                  <b-badge v-else variant="warning">Tạm dừng</b-badge>
                </b-col>
                <b-col
                  ><b>Thời gian cập nhật: </b>
                  {{ formInfo.updated_at | formatDateTime }}</b-col
                >
                <b-col></b-col>
              </b-row>
              <hr />
              <b-table
                :items="items"
                :fields="fields"
                ref="table"
                striped
                hover
                responsive
                caption-top
              >
                <template #cell(index)="data">
                  {{ (currentPage - 1) * 25 + (data.index + 1) }}
                </template>
                <template #cell(type_link)="data">
                  <span v-if="data.item.type_link == 'WALLET'"
                    >Thanh toán ví</span
                  >
                  <span v-else>{{ data.item.type_link }}</span>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination-nav
                  v-model="currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.total"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const CmsRepository = RepositoryFactory.get("cms");
export default {
  mixins: [Common],
  data() {
    return {
      formInfo: {},
      idAutoPayment: null,
      fields: [
        { index: "#" },
        { wallet_transaction_id: "Mã giao dịch" },
        { type_link: "Loại" },
        { total: "Số tiền" },
        { user_phone: "Tài khoản nguồn" },
        { status: "Trạng thái" },
        { title: "Chi tiết" },
        { description: "Lý do" },
        { created_at: "Ngày giao dịch" },
        { updated_at: "Thời gian hoàn thành" },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        lastPage: 1,
        total: 1,
        skip: 0,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Chi tiết đăng ký thanh toán tự động", route: "list" },
    ]);
  },
  methods: {
    listAll() {
      if (typeof this.$route.params.id == "undefined") {
        this.notifyAlert("warn", "Không tìm thấy Id");
      }
      this.$bus.$emit("show-loading", true);
      let params = { id: this.$route.params.id };
      CmsRepository.detailAutoPayment(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.formInfo = response.data.data;
            this.getTransactions(1);
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
    getTransactions(numberPage = null) {
      if (this.$route.query.page !== "undefined" && numberPage == null) {
        numberPage = this.$route.query.page;
      } else {
        numberPage = 1;
      }
      this.$route.query.page = numberPage;
      let params = {
        page: numberPage,
        order_code: this.formInfo.order_code,
      };
      CmsRepository.transactionOrderInfo(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn",'Không tìm thấy giao dịch');
          } else {
            this.items = response.data.data.data;
            this.paginate.total = response.data.data.last_page;
            this.paginate.lastPage = response.data.data.last_page;
            this.notifyAlert("success", "Lấy giao dịch thành công");
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
  },
  watch: {
    currentPage() {
      this.getTransactions();
    },
  },
  created() {
    this.listAll();
  },
};
</script>
